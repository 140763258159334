import React from "react"

import {
  Container,
  SEO,
  ThinContainer,
  MinButton,
  Button,
} from "../components/common"

import "./email-success.css"
import { tweetConversion } from "../utils"

const TWEET_TEXT = `Check out Codioful.io, a beautiful multi-layered & colorful CSS
Gradients pack for your next project by @HiShahadat #cssgradient #codioful`

const EmailSuccess = () => (
  <div className="email-success">
    <Container>
      <SEO title="Subscription Confirmed" />
      <div className="email-success-page">
        <div className="email-success-hero">
          <p>Woohoo!</p>
          <p>You Are in The List!!</p>
        </div>
        <ThinContainer>
          <div className="email-success-content">
            <div className="email-success-text">
              <p>
                I am excited to share my upcoming project. But for now, you can
                help Codioful spread and grow with just a tweet!
              </p>
            </div>
            <div className="email-success-twitter">
              <span>
                <img
                  alt="twitter"
                  src={require("../assets/icon/twitter.svg")}
                />
              </span>
              <p>{TWEET_TEXT}</p>
            </div>
            <div className="email-success-twitter-action">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${tweetConversion(
                  TWEET_TEXT
                )}`}
              >
                <Button btnStyle={{ background: "#1DA1F2" }}>
                  <MinButton
                    btnStyle={{ fontWeight: "bold" }}
                    leftIcon="icon-twitter"
                    title="Click To Tweet"
                  />
                </Button>
              </a>
            </div>
          </div>
        </ThinContainer>
      </div>
    </Container>
  </div>
)

export default EmailSuccess
